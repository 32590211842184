import React from "react";
import fb from "../../assets/img/facebook.ico";
import insta from "../../assets/img/insta.ico";
import youtube from "../../assets/img/youtube.ico";
import twitter from "../../assets/img/twitter.ico";
import "./footer.css";
import logo from "../../assets/img/logo.PNG";
import {Link} from 'react-router-dom';

export default function Footer(){
    return (
        <div className="footer">
            <div className="sb__footer section__padding">
                <div className="sb__footer-links">
                    <div className="sb__footer-links_div">
                        <h4>Infrastructure</h4>
                        <Link to="infrastucture">
                            <p>Campus Safety</p>
                        </Link>
                        <Link to="infrastucture">
                            <p>Health & Wellness</p>
                        </Link>
                        <Link to="infrastucture">
                            <p>Transport</p>
                        </Link>
                        <Link to="infrastucture">
                            <p>Library</p>
                        </Link>
                        <Link to="infrastucture">
                            <p>Sports Infrastructure</p>
                        </Link>
                    </div>
                    <div className="sb__footer-links_div">
                    <h4>Admission</h4>
                        <Link to="admission">
                            <p>Admission Guidelines</p>
                        </Link>
                        <Link to="admission">
                            <p>Mandatory Disclosure</p>
                        </Link>
                        <Link to="admission">
                            <p>School Timings</p>
                        </Link>
                        <Link to="admission">
                            <p>School Uniforms</p>
                        </Link>
                        <Link to="admission">
                            <p>FAQ</p>
                        </Link>
                    </div>
                    <div className="sb__footer-links_div">
                    <h4>About Us</h4>
                        <Link to="about">
                            <p>About Sunshine</p>
                        </Link>
                        <Link to="about">
                            <p>Mission & Vision</p>
                        </Link>
                        <Link to="about">
                            <p>Management</p>
                        </Link>
                        <Link to="about">
                            <p>Affiliations</p>
                        </Link>
                        <Link to="about">
                            <p>Career</p>
                        </Link>
                    </div>
                    <div className="sb__footer-links_div">
                        <img src={logo} alt="logo" />
                        
                        <div className="socialmedia">
                            <p><img src={youtube} alt="youtubeImage" /></p>
                            <p><img src={twitter} alt="twitterImage" /></p>
                            <p><img src={fb} alt="facebookImage" /></p>
                            <p><img src={insta} alt="instagramImage" /></p> 
                        </div>
                    </div>
                </div>

                

                <div className="sb__footer-below" style={{borderTop:'1px solid',borderColor:'rgb(175, 175, 179)'}}>
                    <div className="sb__footer-copyright">
                        <p>
                            Copyright © 2023 Sunshine School. All Rights Reserved.
                        </p>
                    </div>
                    <div className="sb__footer-below-links">
                        <Link to="#"><div><p>Terms & conditions</p></div></Link>
                        <Link to="#"><div><p>privancy</p></div></Link>
                        <Link to="#"><div><p>security</p></div></Link>
                        <Link to="#"><div><p>cookie declaration</p></div></Link>
                    </div>
                </div>

            </div>
        </div>
    );
}