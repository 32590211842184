import React from "react";
import './infrastructure.css';
import schimg from '../../assets/img/schools-entrance.jpg';
import classimg from '../../assets/img/classroom.jpeg';
import labimg from '../../assets/img/lab.jpeg';
import sportsroomimg from '../../assets/img/sportsroom.jpeg';
import artimg from '../../assets/img/artspace.jpeg';

export default function Infrastucture(){
    return(
        <div>
            <div className='contact-top-div'>
                <div className="infra-title">
                    <h1 style={{paddingTop:'20px'}}>Infrastucture</h1>
                </div>
            </div> 
            <div className="container">
                <p>
                Welcome to Sunshine School, where learning shines bright in a nurturing and innovative environment. Our commitment to providing an exceptional educational experience extends to our state-of-the-art infrastructure, designed to inspire and support the growth of our students.
                </p>
                <br/>
                <div className="row">
                    <div className="col">
                        <h3>Campus Design:</h3>
                        <p>
                            Our campus is thoughtfully designed to create a welcoming and conducive atmosphere for learning. Situated amidst lush greenery, our school offers a serene and refreshing environment that stimulates creativity and a love for nature. The architecture seamlessly blends modern aesthetics with functional spaces, ensuring a harmonious balance between form and functionality.
                            </p>
                    </div>
                    <div className="col">
                        <div className="card card-1">
                            <img src={schimg} width={500} height={300} alt="schimg"/>
                        </div>
                    </div>
                </div>
            
                <div className="row">
                    <div className="col">
                        <h3>Classrooms:</h3>
                        <p>
                            Our classrooms are spacious, well-lit, and equipped with the latest educational technology. Each classroom is thoughtfully designed to promote active learning, collaboration, and student engagement. Comfortable seating, interactive whiteboards, multimedia systems, and high-speed internet connectivity create an immersive learning experience that enhances knowledge retention and fosters critical thinking skills.
                        </p>        
                    </div>
                    <div className="col">
                        <div className="card card-1">
                            <img src={classimg} width={500} height={300} alt="classimg"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h3>Laboratories:</h3>
                        <p>
                        We understand the importance of hands-on learning, which is why Sunshine School boasts fully equipped laboratories. Our science, computer, and language laboratories are equipped with cutting-edge tools and equipment, enabling students to explore and experiment under the guidance of experienced instructors. From conducting scientific experiments to coding and programming, our laboratories provide a safe and stimulating environment for practical learning.
                        </p>        
                    </div>
                    <div className="col">
                        <div className="card card-1">
                        <img src={labimg} width={500} height={300} alt="labimg"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card card-1">
                        <img src={sportsroomimg} width={500} height={300} alt="sportsroomimg"/>
                        </div>
                    </div>
                    <div className="col">
                        <h3>Sports Facilities:</h3>
                        <p>
                        We believe in the holistic development of our students, which includes promoting physical fitness and a spirit of sportsmanship. Our school features top-notch sports facilities, including a multipurpose sports hall, outdoor sports fields, and courts for basketball, volleyball, badminton, and more. These facilities not only enable students to excel in their favorite sports but also teach valuable life skills such as teamwork, discipline, and resilience.
                        </p>        
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card card-1">
                        <img src={artimg} width={500} height={300} alt="artImage" />
                        </div>
                    </div>
                    <div className="col">
                        <h3>Art and Performing Arts Spaces:</h3>
                        <p>
                        At Sunshine School, we recognize the importance of nurturing creativity and self-expression. Our dedicated art studios and performing arts spaces provide students with the opportunity to explore their artistic talents. From painting and sculpting to music, drama, and dance, these spaces inspire imagination and allow students to showcase their creativity through various forms of artistic expression.
                        </p>        
                    </div>
                </div>
                
                <p>
                At Sunshine School, we believe that a conducive and inspiring infrastructure plays a vital role in nurturing the potential of our students. Our modern and thoughtfully designed campus serves as a catalyst for academic excellence, creativity, and personal growth. We invite you to experience our exceptional infrastructure firsthand and become a part of the Sunshine School family, where every child's educational journey is brightened by an environment that fosters learning, innovation, and success.
                </p>
            </div>
        </div>
    );
}