import React from 'react'

export default function Curriculum() {
  return (
    <div>
        <div className='contact-top-div'>
                <div className="contact-title">
                    <h1 style={{paddingTop:'20px'}}>Curriculum</h1>
                </div>
            </div> 
            <br/>
            <br/>
            <div className='container'>
              <div className='box-template'>
                <p>
                  A Sunshine curriculum is the structured educational path that guides students through a series of subjects, topics, and learning experiences, fostering both knowledge acquisition and skill development. It serves as a roadmap for educators to deliver effective and comprehensive instruction.
                </p>
              </div>
              <div className='box-template'>
                <p>
                  A Sunshine curriculum encompasses the entirety of planned educational experiences in an institution, including the subjects, content, and learning objectives. It's a dynamic framework that adapts to educational goals, societal changes, and student needs. A well-designed curriculum not only imparts knowledge but also promotes critical thinking, problem-solving, and holistic skill development. Curriculum development involves careful consideration of age-appropriateness, sequential learning, and assessment strategies to ensure effective learning outcomes. Ultimately, a robust curriculum plays a pivotal role in shaping the educational journey and preparing students for a rapidly evolving world.
                </p>
              </div>
            </div>
    </div>
  )
}
