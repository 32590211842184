import React from "react";
import call from "../../assets/img/ring.png";
import formimg from "../../assets/img/form.png";
import contact from "../../assets/img/contact.png";
import "./header.css"
import whatsapp from "../../assets/img/whatsapp.png"

function Infobar(){
    return(
        <div>
            <div className="whatsapp">
                <a href="https://wa.me/919741220000" target="new">
                    <img src={whatsapp} width={50} alt="whatsapp"/>
                </a>
            </div>
            <div className="main">
                {/* caller icon */}
                <div className="icon btn-icon">
                    <img src={call} alt="call" />
                    <span>+91-97412 20000</span>&nbsp;
                </div>

                {/* localtion icon */}
                {/* <div className="icon">
                    <img src={location} alt="location" onClick={openMap} />
                    <span>ITI - Layout</span>&nbsp;&nbsp;
                </div> */}

                {/* envolp icon */}
                <div className="icon btn-icon1">
                    <img src={formimg} alt="envelop" />
                    {/* <span>info@sunshineenglishschool.com</span> */}
                    <span>INQUIRY FORM</span>&nbsp;
                </div>

                

                {/* localtion icon */}
                <div className="icon btn-icon2">
                    <img src={contact} alt="location" onClick={openMap} />
                    <span>PARENT LOGIN</span>&nbsp;
                </div>
            </div>
        </div>
        
        
    );
}

function openMap(){
    window.open(
        "https://www.google.com/search?rlz=1C5CHFA_enIN1019IN1019&tbs=lf:1,lf_ui:2&tbm=lcl&q=sunshine+school&rflfq=1&num=10&ved=2ahUKEwjOl__GjLP-AhWQSWwGHVWTD3QQtgN6BAgYEAc#rlfi=hd:;si:4466212194668066227,l,Cg9zdW5zaGluZSBzY2hvb2xIuouVhKG4gIAIWhkQABABGAAYASIPc3Vuc2hpbmUgc2Nob29skgEGc2Nob29sqgFBCggvbS8wNnpkahABKhMiD3N1bnNoaW5lIHNjaG9vbCgAMh4QASIaSKMs1V99VB7a9tdH4XUXDHpOoX082rBXomc;mv:[[13.039633799999999,77.7180845],[12.885678200000001,77.4719428]]",
        "_blank", "noreferrer"
        )
}

export default Infobar;