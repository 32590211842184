import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../Styles/main.css";
import logo from "../assets/img/logo.PNG";
import { Link } from 'react-router-dom';

export default function Navbar(){
    const navRef = useRef();

    const showNavBar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    return(
        <header>
            <img src={logo} alt="logo" />
            <nav ref={navRef}>
                <Link to="/home" onClick={showNavBar}>HOME</Link>
                <Link to="/infrastucture" onClick={showNavBar}>INFRASTRUCTURE</Link>
                <Link to="/curriculum" onClick={showNavBar}>CURRICULUM</Link>
                <Link to="/admission" onClick={showNavBar}>ADMISSION</Link>
                <Link to="/about" onClick={showNavBar}>ABOUT SUNSHINE</Link>
                <Link to="/contact" onClick={showNavBar}>CONTACT</Link>
                <button className="nav-btn nav-close-btn" onClick={showNavBar}>
                    <FaTimes></FaTimes>
                </button>
            </nav>
            <button className="nav-btn" onClick={showNavBar}>
                <FaBars></FaBars>
            </button>
        </header>
    );
}