import '../../Styles/main.css';
import './contact.css';
import React, { useState } from 'react';
import map from '../../assets/img/map.png'; 

export default function Contact() {
    const [data, setData] = useState({});
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({...data, [name]: value})
        
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const name=data.name;
        const email=data.email;
        const phone=data.phone;
        const date=data.date;
        const message=data.message;
        window.open(
            "mailto:info@sunshineenglishschool.com?subject='appointment with sunshine'&body=Hi Sunshine, Name : "+name+", Email : "+email+", Phone : "+phone+", Date : "+date+", Message : "+message+"",
            "_blank", "noreferrer"
        )
    }
    return(
        <div>      
            <div className='contact-top-div'>
                <div className="contact-title">
                    <h1 style={{paddingTop:'20px'}}>Contact Us</h1>
                    <h4 style={{color:'white',textAlign:'center'}}>
                        Phone : +91-9741220000 | Landline : 080-47055444 or Email : info@sunshineenglishschool.com
                    </h4>
                    <h4 style={{color:'white',textAlign:'center'}}>Address : ITI Layout, Mangammanapalya, opp. Masjid-e-Noor, Bommanahalli, Bengaluru, Karnataka 560068</h4>
                </div>
            </div>      
            
            <div className="contact">
                <div className="row">
                    <div className="col form">
                        <h2>Make an Appointment</h2>
                        <form method='post' onSubmit={handleSubmit}>
                            <div className='formappointment'>
                                <input type='text' className='txt' name="name" onChange={handleChange} value={data.name} placeholder='Name'/>
                                <input type='text' className='txt' name="email" onChange={handleChange} value={data.email} placeholder='Email' />
                                <input type='text' className='txt' name="phone" onChange={handleChange} value={data.phone} placeholder='Phone Number' />
                                <input type='text' className='txt' name="date" onChange={handleChange} value={data.date} placeholder='Choose a Date' />
                                <input type='text' className='txt' name="message" onChange={handleChange} value={data.message} placeholder='Message' />
                                <input type='submit' className='btn secondary' value='Submit Form' />
                            </div>
                        </form>
                    </div>
                    <div className="col">
                        <div className="map">
                            <a target='new' href='https://www.google.com/search?rlz=1C5CHFA_enIN1019IN1019&tbs=lf:1,lf_ui:2&tbm=lcl&q=sunshine+school&rflfq=1&num=10&ved=2ahUKEwjOl__GjLP-AhWQSWwGHVWTD3QQtgN6BAgYEAc#rlfi=hd:;si:4466212194668066227,l,Cg9zdW5zaGluZSBzY2hvb2xIuouVhKG4gIAIWhkQABABGAAYASIPc3Vuc2hpbmUgc2Nob29skgEGc2Nob29sqgFBCggvbS8wNnpkahABKhMiD3N1bnNoaW5lIHNjaG9vbCgAMh4QASIaSKMs1V99VB7a9tdH4XUXDHpOoX082rBXomc;mv:[[13.039633799999999,77.7180845],[12.885678200000001,77.4719428]]'>
                                <img src={map} className='mapimg' alt='' />
                            </a> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}