import './App.css';
import React from 'react';
import Navbar from './Components/navbar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages/home/home';
import About from './pages/about/about';
import NoPage from './pages/nopage';
import Contact from './pages/contact/contact';
import Infrastucture from './pages/infrastructure/infrastructure';
import Admission from './pages/admission/admission';
import Curriculum from './pages/curriculum/curriculum'; 
import Infobar from './Components/header/header';
import Footer from './Components/footer/footer';
import ScrollToTop from "react-scroll-to-top";

function App() {
  const srcollToTop={width:'50px',height:'50px',borderRadius:'40px',backgroundColor:'#EC6A1F',color:'#fff'};
  window.addEventListener("scroll",function(){
    var header= document.querySelector("header");
    header.classList.toggle("sticky", window.scrollY > 0 );
  });

  return (
    <Router>
      <ScrollToTop smooth style={srcollToTop} color='white' viewBox='0 0 250 150'></ScrollToTop>
      <Infobar />
      <React.Fragment>
        <Navbar />
      </React.Fragment>
      <Routes>
        <Route exact path='/' Component={Home} />
        <Route exact path='/home' Component={Home} />
        <Route exact path='/admission' Component={Admission}/>
        <Route exact path='/about' Component={About} />
        <Route exact path='/contact' Component={Contact} />
        <Route exact path='/infrastucture' Component={Infrastucture} />
        <Route exact path='/curriculum' Component={Curriculum} />
        <Route path="*" Component={NoPage} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
