import React from 'react';
import img1 from '../../assets/banner/1.jpg';
import img2 from '../../assets/banner/2.jpg';
import img3 from '../../assets/banner/3.jpg';
import img4 from '../../assets/banner/4.jpg';
import "./banner.css"

function loadimg(counter){
    const timeoutFunction = setInterval(function(){
        if(document.getElementById('radio'+counter)!=null){
            document.getElementById('radio'+counter).checked = true;
        }
        counter++;
        if(counter > 4 ){
            counter = 1;
        }
        }, 5000)
    return () => clearInterval(timeoutFunction);
}

export default function Banner(){    
    const counter = 1;
    loadimg(counter);
    return (
        <div className='bannerbody'>
            <p id="banner"></p>
            <div className="slider">
                <div className="slides">
                    <input type="radio" name="radio-btn" id="radio1" />
                    <input type="radio" name="radio-btn" id="radio2" />
                    <input type="radio" name="radio-btn" id="radio3" />
                    <input type="radio" name="radio-btn" id="radio4" />
                    {/* slide images start */}
                    <div className="slide first">
                        <img src={img1} alt="" />
                    </div> 
                    <div className="slide">
                        <img src={img2} alt="" />
                    </div> 
                    <div className="slide">
                        <img src={img3} alt="" />
                    </div> 
                    <div className="slide">
                        <img src={img4} alt="" />
                    </div> 
                    {/* slide images end */}

                    {/* automatic navigation start */}
                    <div className='navigation-auto'>
                        <div className='auto-btn1'></div>
                        <div className='auto-btn2'></div>
                        <div className='auto-btn3'></div>
                        <div className='auto-btn4'></div>
                    </div>
                    {/* automatic navigation end */}
                </div>
                {/* manual navigation start */}
                    <div className='navigation-manual'>
                        <label htmlFor="radio1" className='manual-btn'></label>
                        <label htmlFor="radio2" className='manual-btn'></label>
                        <label htmlFor="radio3" className='manual-btn'></label>
                        <label htmlFor="radio4" className='manual-btn'></label>
                    </div>
                {/* manual navigation end */}
            </div>
        </div>
    );
}