import React from "react";
import "./home.css";
import curriculimg from '../../assets/img/curriculum.jpeg';
import Cards from '../../Components/Cards/cards';
import whyimg from '../../assets/img/question.png';
import Banner from "../../Components/banner/banner";
import welcomimg from '../../assets/img/welcom.jpeg';
import facultyimg from '../../assets/img/faculty.jpeg';
import learningimg from '../../assets/img/learning.webp';
import networkimg from '../../assets/img/network.jpeg';
import cultureimg from '../../assets/img/culture.png';
import supportimg from '../../assets/img/support.jpeg';
import deeniyatimg from '../../assets/img/deen.jpeg';
import taekwondoimg from '../../assets/img/taekwon.jpg';
import sportimg from '../../assets/img/sports.jpg';

export default function Home(){
    return(
        <div>
            <Banner/>
            <div className="alert">
                <div className="notifications">
                    <div className="marquee">
                        <p>Admission open for 2023-24 (you may visit the school from Monday to Saturday between 9:00am to 4:00pm)</p>
                    </div>
                </div>
            </div>
            <Cards/>
            <br></br>
            <div className="container" style={{padding:'1rem'}}>
                <div className="row">
                    <div className="col-md-6" style={{paddingLeft:'20px'}}>
                        <h3 className="h2-title">Welcome to Sunshine School!</h3>
                        <p>
                            At Sunshine School, we believe in harnessing the power of pioneering spirit and fostering a supportive environment for aspiring entrepreneurs to thrive. Our goal is to equip individuals with the knowledge, skills, and mindset necessary to launch successful startups and make a lasting impact in the business world.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="welcome" style={{textAlign:'center'}}>
                            <img src={welcomimg} width={540} height={358} alt="welcomimg"/>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div className="why-img">
                <div className="container">
                    <div className="row" style={{padding:'20px 5px 5px 5px'}}>
                        <div className="col-md-6" style={{paddingTop:'50px'}}>
                            <h3 className="h2-title">Why Choose Sunshine School?</h3><br/>
                            <h4>Nurturing pioneering Mindset:</h4>
                            <p>
                                We cultivate an pioneering mindset that encourages creativity, resilience, and a growth-oriented approach. Our curriculum is designed to instill confidence, innovative thinking, and the ability to adapt to the ever-changing business landscape.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img src={whyimg} className="whyimg" alt="why"/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{backgroundColor:'#ffbf00',textAlign:'center'}}>
                <div style={{padding:'40px'}}>
                        <div className="card1 card-1">
                            <img src={deeniyatimg} width={250} height={200} alt="deeniyatimg"/>
                            <div className="card-desc">
                                <h6>Deeniyat</h6>
                            <p style={{fontSize:'12px'}}>
                                Deeniyat offers a comprehensive platform for religious education, fostering a deeper understanding of Islamic teachings and values in the modern world
                            <br/> <span style={{color:'skyblue'}}>read more ...</span>
                            </p>
                            </div>
                            
                        </div>
                        <div className="card1 card-1">
                            <img src={taekwondoimg} width={250} height={200} alt="taekwondoimg"/>
                            <div className="card-desc">
                                <h6>Taekwondo</h6>
                            <p style={{fontSize:'12px'}}>
                            Taekwondo is a dynamic martial art that combines powerful kicks and precise techniques, promoting physical fitness and mental discipline.
                            <br/> <span style={{color:'skyblue'}}>read more ...</span>
                            </p>
                            
                            </div>
                        </div>
                        <div className="card1 card-1">
                            <img src={sportimg} width={250} height={200} alt="sportimg"/>
                            <div className="card-desc">
                                <h6>Sports</h6>
                            <p style={{fontSize:'12px'}}>
                                Sports encompass a diverse realm of physical activities that inspire teamwork, competition, and the pursuit of excellence.
                                <br/> <span style={{color:'skyblue'}}>read more ...</span>
                            </p>
                            
                            </div>
                        </div>
                        <div className="card1 card-1" style={{background:'none',color:'#fff',height:'0px'}}>
                            <div className="card-desc">
                                <h4>Special Courses</h4>
                            <p>
                                Embark on a transformative journey of self-discovery and personal growth through our immersive "Mindful Explorations" course, where ancient wisdom meets modern neuroscience to cultivate resilience and inner harmony.
                            </p>
                            <br/><br/>
                            </div>
                        </div>
                </div>
            </div>
            <div className="container">
                <div className="row" style={{paddingTop:'50px'}}>
                    <div className="col-md-6" style={{paddingLeft:'20px',paddingTop:'50px'}}>
                    <h4>Comprehensive Curriculum:</h4>
                        <p>
                            Our carefully crafted curriculum covers all aspects of development, from ideation and validation to market research, business strategy, marketing, finance, and more. We provide a holistic learning experience that combines theoretical knowledge with practical application.
                        </p>
                    </div>
                    <div className="col-md-6" style={{textAlign:'center'}}>
                        <div className="card card-1">
                            <img src={curriculimg} width={400} height={300} alt="curriculimg"/>
                        </div>
                    </div>
                </div>
                
                <div className="row" style={{paddingTop:'50px'}}>
                    <div className="col-md-5">
                        <div className="card card-1">
                            <img src={facultyimg} width={400} height={300} alt="facultyimg"/>
                        </div>
                    </div>
                    <div className="col-md-6" style={{paddingTop:'50px'}}>
                        <h4>Experienced Faculty:</h4>
                        <p>
                            Our team of experienced entrepreneurs, industry experts, and seasoned professionals bring a wealth of knowledge and real-world experience to the classroom. They provide valuable insights, mentorship, and guidance to help students navigate the challenges of starting and scaling a business.
                        </p>
                    </div>
                        
                </div>
                <div className="row" style={{paddingTop:'50px'}}>
                    <div className="col-md-6" style={{paddingLeft:'20px',paddingTop:'50px'}}>
                        <h4>Hands-on Learning:</h4>
                        <p>
                            At Sunshine School, we emphasize experiential learning. Students actively participate in group projects, case studies, workshops, and simulations to apply their knowledge and develop critical pioneering skills. We believe in learning by doing and encourage students to take risks, learn from failures, and iterate on their ideas.
                        </p>
                    </div>
                    <div className="col-md-6" style={{textAlign:'center'}}>
                        <div className="card card-1" >
                            <img src={learningimg} width={400} height={300} alt="learningimg"/>
                        </div>
                    </div>
                </div>
                <div className="row" style={{paddingTop:'50px'}}>
                    <div className="col-md-5">
                        <div className="card card-1">
                            <img src={networkimg} width={400} height={300} alt="networkimg"/>
                        </div>
                    </div>
                    <div className="col-md-6" style={{paddingTop:'50px'}}>
                        <h4>
                        Network and Collaboration:
                        </h4>
                        <p>
                            We understand the importance of building a strong network in the startup ecosystem. Sunshine School offers numerous networking opportunities, industry connections, and access to a vibrant community of like-minded individuals. Collaborative projects, guest lectures, and events foster valuable connections that can lead to future partnerships and investment opportunities.
                        </p>
                    </div>
                    
                </div>
                <div className="row" style={{paddingTop:'50px'}}>
                    <div className="col-md-6" style={{paddingLeft:'20px',paddingTop:'60px'}}>
                        <h4>pioneering Culture:</h4>
                        <p>
                            Sunshine School prides itself on creating a dynamic and inclusive pioneering culture. We celebrate diversity, encourage collaboration, and promote an environment where students can share ideas, seek feedback, and collaborate on projects. Our campus is a hub of innovation, creativity, and passion.
                        </p>
                    </div>
                    <div className="col-md-6" style={{textAlign:'center'}}>
                        <div className="card card-1" style={{textAlign:'center'}}>
                            <img src={cultureimg} width={400} height={300} alt="cultureimg"/>
                        </div>
                    </div>
                </div>
                <div className="row" style={{paddingTop:'50px'}}>
                    <div className="col-md-5">
                        <div className="card card-1">
                            <img src={supportimg} width={400} height={300} alt="supportimg"/>
                        </div>
                    </div>
                    <div className="col-md-6" style={{paddingTop:'50px'}}>
                        <h4>Incubation and Support:</h4>
                        <p>
                            As part of our commitment to student success, Sunshine School provides post-program support through incubation and mentorship programs. We help students refine their business ideas, develop prototypes, secure funding, and launch their startups. Our network of investors, advisors, and alumni plays an active role in guiding and supporting our students throughout their pioneering journey.
                        </p>
                    </div>
                </div>
                    <p>
                        Whether you're a budding entrepreneur with a game-changing idea or someone looking to develop the skills to thrive in a environment, Sunshine School is the ideal launchpad for your pioneering journey. Join us and let's illuminate the path to your success!
                    </p>
                    <p style={{color:'#EC6A1F',fontWeight:'600'}}>
                        Enroll now and be a part of the Sunshine School community. Together, we can turn your pioneering dreams into reality.
                    </p>
            </div>
            
        </div>

    );
}