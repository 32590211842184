import React from "react";
import '../infrastructure/infrastructure.css';
import './admission.css';

export default function Admission() {
    return(
        <div>
            <div className='contact-top-div'>
                <div className="infra-title">
                    <h1 style={{paddingTop:'20px'}}>Admission</h1>
                </div>
            </div> 
            <div className="container">
                <p>
                Thank you for considering Sunshine School for your child's educational journey. We are delighted to offer an admission process that ensures a seamless and inclusive experience for both students and parents. At Sunshine School, we believe in fostering a diverse community of learners who are eager to explore, grow, and shine in all aspects of their lives.
                </p>
                <div className="box-template">
                <h3>Our Admissions Philosophy:</h3>
                <ul>
                    <li>
                    <b>Holistic Approach: </b>We embrace a holistic approach to admissions, valuing each child as an individual with unique talents, interests, and potential. We consider academic abilities, character traits, extracurricular involvement, and personal aspirations to ensure a well-rounded student body.
                    </li>
                    <li>
                    <b>Inclusive Environment: </b>Sunshine School celebrates diversity and strives to create an inclusive environment that respects and appreciates students from all backgrounds. We welcome students of various cultural, ethnic, and socio-economic backgrounds, fostering a rich tapestry of experiences and perspectives within our school community.
                    </li>
                    <li>
                    <b>Collaborative Partnership: </b>We believe that a strong partnership between parents, students, and the school is vital for a child's success. We encourage open communication and collaboration, working together to support the growth and development of each student.
                    </li>
                </ul>
                </div>
                <div className="box-template">
                <h3>The Admission Process:</h3>
                <ul>
                    <li>
                    <b>Inquiry and Exploration: </b>To begin the admission process, we invite you to reach out to Sunshine School through our website, phone, or in-person visit. Our admissions team will provide you with detailed information, answer your questions, and guide you through the process.
                    </li>
                    <li>
                    <b>Application Submission: </b>Once you have explored our programs and are ready to move forward, you can complete the online application form. This form will gather important details about your child's academic history, interests, and any special considerations that may be relevant.
                    </li>
                    <li>
                    <b>Entrance Assessment: </b>As part of our commitment to academic excellence, we conduct an entrance assessment to understand your child's current academic abilities. The assessment is designed to be age-appropriate, ensuring a fair evaluation of their skills and potential. We may also request any additional documentation, such as school records or reference letters.
                    </li>
                    <li>
                    <b>Student and Parent Interaction: </b>We value the opportunity to get to know your child and your family better. We may schedule an informal meeting or interview to understand your child's interests, goals, and aspirations. This interaction also provides an opportunity for you to ask questions and learn more about Sunshine School.  
                    </li>
                    <li>
                    <b>Admission Decision: </b>Following the completion of the assessment and interaction, our admissions committee carefully reviews each application. The decision is based on various factors, including the student's academic readiness, alignment with our values, and available spaces in the desired grade level.
                    </li>
                    <li>
                    <b>Acceptance and Enrollment: </b>If your child is accepted, congratulations! You will receive an acceptance letter outlining the details of enrollment, including fee structure, important dates, and required documentation. To secure the admission, you will be requested to complete the enrollment process within the specified timeframe.
                    </li>
                </ul>
                </div>
                <div className="box-template">
                <h3>Scholarship and Financial Aid:</h3>
                
                <p>
                    At Sunshine School, we are committed to making education accessible to deserving students. We offer a limited number of scholarships and financial aid opportunities to those who demonstrate exceptional potential, talent, or financial need. Please inquire with our admissions team for more information on the scholarship application process and eligibility criteria.
                </p>
                </div>
                <p>
                    We understand that choosing the right school for your child is a significant decision. At Sunshine School, we strive to provide a transparent and supportive admissions process that ensures a smooth transition into our vibrant learning community. We look forward to welcoming your child and embarking on an educational journey that will illuminate their path to excellence.
                </p>
                <p>
                    Contact us today to begin the admissions process at Sunshine School and open the doors to a bright and inspiring future for your child.
                </p>
                
            </div>
        </div>
    );
}