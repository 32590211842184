
import img1 from '../../assets/cards/languages.png';
import img2 from '../../assets/cards/calculator.png';
import img3 from '../../assets/cards/microscope.png';
import img4 from '../../assets/cards/paint-palette.png';
import img5 from '../../assets/cards/sports.png';
import img6 from '../../assets/cards/technology.png';
import img7 from '../../assets/cards/quran.png';
import img8 from '../../assets/cards/taekwondo.png';
import "./cards.css"

export default function Cards(){
    return (
        <div>
                <div className='cards'>
                    <figure>
                        <img className='img-card' src={img1} alt='image1'/>
                        <figcaption className='card-title'>LANGUAGE</figcaption>
                    </figure>
                    <figure>
                        <img className='img-card' src={img2} alt='image1'/>
                        <figcaption className='card-title'>MATHEMATICS</figcaption>
                    </figure>
                    <figure>
                        <img className='img-card' src={img3} alt='image1'/>
                        <figcaption className='card-title'>SCIENCE</figcaption>
                    </figure>
                    <figure>
                        <img className='img-card' src={img4} alt='image1'/>
                        <figcaption className='card-title'>ARTS</figcaption>
                    </figure>
                    <figure>
                        <img className='img-card' src={img5} alt='image1'/>
                        <figcaption className='card-title'>SPORTS</figcaption>
                    </figure> 
                    <figure>
                        <img className='img-card' src={img6} alt='image1'/>
                        <figcaption className='card-title'>TECHNOLOGY</figcaption>
                    </figure>
                    <figure>
                        <img className='img-card' src={img7} alt='image1'/>
                        <figcaption className='card-title'>DEENIYAT</figcaption>
                    </figure>
                    <figure>
                        <img className='img-card' src={img8} alt='image1'/>
                        <figcaption className='card-title'>TAEKWONDO</figcaption>
                    </figure>
                </div>
           
        </div>
    )
}