import React from "react";
import '../infrastructure/infrastructure.css';

export default function About() {
    return(
        <div>
            <div className='contact-top-div'>
                <div className="infra-title">
                    <h1 style={{paddingTop:'20px'}}>About Us</h1>
                </div>
            </div>
            <div className="container">
                <p>
                Welcome to Sunshine School, a place where education radiates with brilliance and where each child's potential is nurtured with love and care. With a deep commitment to excellence in education, we strive to create a vibrant learning community that empowers students to shine academically, socially, and emotionally.
                </p>
                <div className="box-template">
                <h3>Our Philosophy:</h3>
                    <p>
                        At Sunshine School, we believe that education is not merely about imparting knowledge but also about igniting a lifelong love for learning. Our philosophy is centered on the following principles:
                    </p>
                    <ul>
                        <li>
                        <b>Holistic Development: </b>We recognize the importance of nurturing the whole child. Our educational approach is designed to foster academic excellence while promoting personal growth, character development, creativity, critical thinking, and emotional intelligence.
                        </li>
                        <li>
                        <b>Student-Centered Learning: </b>We believe that each child is unique and possesses their own learning style, interests, and strengths. Our curriculum and teaching methodologies are tailored to meet the individual needs of students, allowing them to explore their passions and reach their full potential.
                        </li>
                        <li>
                        <b>Values-Based Education: </b>We instill in our students a strong foundation of values, ethics, and social responsibility. We foster a culture of respect, empathy, and integrity, encouraging students to become compassionate global citizens who contribute positively to society.
                        </li>
                        <li>
                        <b>Engaging and Innovative Teaching: </b>Our dedicated team of educators is committed to creating a dynamic and engaging learning environment. We employ innovative teaching methodologies, integrating technology, hands-on experiences, and collaborative projects to make learning exciting, relevant, and meaningful.
                        </li>
                        <li>
                        <b>Partnership with Parents: </b>We believe that parents play a crucial role in a child's educational journey. We value open communication and collaborative partnerships with parents, working together to support the holistic development of each student.
                        </li>
                        
                    </ul>
                </div>
                <div className="box-template">
                    <h3>Our Programs:</h3>
                    <p>
                        Sunshine School offers a comprehensive range of programs designed to cater to the diverse needs and interests of students:
                    </p>
                    <ul>
                        <li>
                        <b>Early Childhood Education: </b>Our early childhood program focuses on providing a nurturing and stimulating environment for children aged 3 to 5 years. Through a play-based approach, we lay a strong foundation for future learning, fostering social skills, language development, and cognitive growth.
                        </li>
                        <li>
                        <b>Primary and Middle School: </b>Our primary and middle school programs offer a well-rounded education that balances academic rigor with holistic development. We follow a curriculum that meets international standards while incorporating local cultural elements and values.
                        </li>
                        <li>
                        <b>Co-Curricular Activities: </b>At Sunshine School, we recognize the importance of a well-rounded education. Our co-curricular activities program offers a diverse range of opportunities for students to explore their interests and talents in areas such as sports, arts, music, drama, debate, and community service.
                        </li>
                        <li>
                        <b>Character Education: </b>We believe in nurturing students' character and social-emotional well-being. Our character education program focuses on developing positive values, leadership skills, empathy, resilience, and responsible decision-making.
                        </li>
                        <li>
                        <b>Parental Involvement:</b> We encourage active parental involvement in our school community. We organize workshops, seminars, and parent-teacher meetings to facilitate meaningful engagement and collaboration between parents and educators.
                        </li>
                    </ul>
                </div>
                <div className="box-template">
                    <h3>Our Commitment:</h3>
                    <p>
                        At Sunshine School, our commitment extends beyond academic excellence. We strive to create a safe, inclusive, and supportive learning environment where students feel valued, inspired, and empowered to reach their highest potential. Our dedicated faculty and staff work tirelessly to create an atmosphere that nurtures intellectual curiosity, personal growth, and a lifelong passion for learning.

                        Come, be a part of Sunshine School, where we illuminate minds and nurture hearts, guiding students to become compassionate, confident, and successful individuals who make a positive impact in the world.

                        Contact us today to learn more about Sunshine School and join our vibrant learning community. Let the brilliance of education radiate in your child's life.
                    </p>
                </div>
            </div>
        </div>
    )
}